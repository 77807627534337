
import { computed, ref } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockPencilBanner",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			default: () => ({}),
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const bannerIsClosed = ref(false);

		const bodyCopy = computed(() => {
			let copy = props.settings.bodyCopy || "";
			// some older have not saved banner as rich text still a text
			// checking if html exist if it doesn't adding a p tag by default
			if (!/<\/?[a-z][\s\S]*>/i.test(copy)) copy = `<p>${copy}</p>`;
			return renderData(copy, props.dataSite);
		});

		const label = computed(() =>
			renderData(props.settings.buttonText, props.dataSite)
		);

		const url = computed(() => {
			if (props.settings.buttonLink) {
				return renderData(props.settings.buttonLink, props.dataSite);
			}
			if (props.settings["button-url"]) {
				return renderData(props.settings["button-url"], props.dataSite);
			}
			return null;
		});

		return {
			bannerIsClosed,
			bodyCopy,
			label,
			url,
		};
	},
};
